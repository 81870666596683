import { countries } from "../../constants/countries"
import { KeywordWithPositionEntity } from "../../entities/KeywordEntity"
import * as types from "./types"

interface State {
  keywords: KeywordWithPositionEntity[]
  isFetching: boolean
  selectedKeywordsToDelete: Set<string>
  selectedKeywordsToCreate: Set<string>
  filters: {
    country: string | null
    device: "desktop" | "mobile" | "tablet" | null
    source: "google" | "bing" | "yandex" | null
  }
  addKeywordsModal: {
    isOpen: boolean
    input: string
  }
  keywords_image: string | null
}

const initialState: State = {
  keywords: [],
  isFetching: false,
  selectedKeywordsToDelete: new Set(),
  selectedKeywordsToCreate: new Set(),
  filters: {
    country: null,
    device: null,
    source: null,
  },
  addKeywordsModal: {
    isOpen: false,
    input: "",
  },
  keywords_image: null,
}

export function searchGptKeywordsReducer(
  state = initialState,
  action: types.SearchGptKeywordsActionTypes
): State {
  if (action.type === types.SearchGptKeywordsStoreKeywords) {
    return {
      ...state,
      keywords: action.payload.keywords,
    }
  }

  if (action.type === types.SearchGptKeywordsStoreKeywordsImage) {
    return {
      ...state,
      keywords_image: action.payload.image,
    }
  }

  if (action.type === types.SearchGptKeywordsSetIsFetching) {
    return {
      ...state,
      isFetching: action.payload.value,
    }
  }

  if (action.type === types.SearchGptKeywordsSetSelectedKeywords) {
    const selectedKeywords = new Set(state.selectedKeywordsToDelete)
    if (selectedKeywords.has(action.payload.keyword)) {
      selectedKeywords.delete(action.payload.keyword)
    } else {
      selectedKeywords.add(action.payload.keyword)
    }

    return {
      ...state,
      selectedKeywordsToDelete: selectedKeywords,
    }
  }

  if (action.type === types.SearchGptKeywordsSetSelectedKeywordsToCreate) {
    const selectedKeywords = new Set(state.selectedKeywordsToCreate)
    if (selectedKeywords.has(action.payload.keyword)) {
      selectedKeywords.delete(action.payload.keyword)
    } else {
      selectedKeywords.add(action.payload.keyword)
    }

    return {
      ...state,
      selectedKeywordsToCreate: selectedKeywords,
    }
  }

  if (action.type === types.SearchGptKeywordsSetAddKeywordsModalIsOpen) {
    return {
      ...state,
      addKeywordsModal: {
        ...state.addKeywordsModal,
        isOpen: action.payload.value,
        input: action.payload.value ? state.addKeywordsModal.input : "",
      },
      selectedKeywordsToCreate: !action.payload.value
        ? new Set()
        : state.selectedKeywordsToCreate,
    }
  }

  if (action.type === types.SearchGptKeywordsSetAddKeywordsModalInput) {
    return {
      ...state,
      addKeywordsModal: {
        ...state.addKeywordsModal,
        input: action.payload.value,
      },
    }
  }

  if (action.type === types.SearchGptKeywordsStoreInputToKeywords) {
    return {
      ...state,
      selectedKeywordsToCreate: new Set([
        ...state.selectedKeywordsToCreate,
        ...state.addKeywordsModal.input
          .split(",")
          .map((keyword) => keyword.trim()),
      ]),
      addKeywordsModal: {
        ...state.addKeywordsModal,
        input: "",
      },
    }
  }

  if (action.type === types.SearchGptKeywordsRemoveAllKeywordsForCreate) {
    return {
      ...state,
      selectedKeywordsToCreate: new Set(),
    }
  }

  if (action.type === types.SearchGptKeywordsRemoveAllKeywordsForDelete) {
    return {
      ...state,
      selectedKeywordsToDelete: new Set(),
    }
  }

  if (action.type === types.SearchGptKeywordsSetFiltersCountry) {
    if (
      countries.includes(action.payload.country) === false ||
      action.payload.country === "unknown"
    ) {
      return {
        ...state,
        filters: {
          ...state.filters,
          country: null,
        },
      }
    }
    return {
      ...state,
      filters: {
        ...state.filters,
        country: action.payload.country,
      },
    }
  }

  if (action.type === types.SearchGptKeywordsSetFiltersDevice) {
    return {
      ...state,
      filters: {
        ...state.filters,
        device: action.payload.device,
      },
    }
  }

  if (action.type === types.SearchGptKeywordsSetFiltersSource) {
    return {
      ...state,
      filters: {
        ...state.filters,
        source: action.payload.source,
      },
    }
  }

  if (
    action.type === types.SearchGptKeywordsSelectedKeywordsToCreateRemoveKeyword
  ) {
    const selectedKeywords = new Set(state.selectedKeywordsToCreate)
    selectedKeywords.delete(action.payload.keyword)

    return {
      ...state,
      selectedKeywordsToCreate: selectedKeywords,
    }
  }

  if (action.type === types.SearchGptKeywordsReset) {
    return { ...initialState }
  }

  return state
}
