export const TOOLS_AVAILABLE = [
  "indexation",
  "analytics",
  "settings",
  "keywords",
  "opportunities",
  "shared",
  "logs",
  "sitemapy",
  "dashboard",
  "show-off",
  "roast",
  "search-gpt",
]
