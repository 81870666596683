import { PaymentPlansEntity } from "@foudroyer/interfaces/dist/entities/PaymentEntity"
import { ThunkAction } from "redux-thunk"
import { NotificationMessageEntity } from "../../entities/NotificationEntity"
import { getWebsiteIdFromUrl } from "../../utils/getWebsiteIdFromUrl"
import { actions } from "../actions"
import { RootState } from "../store"
import * as types from "./types"

export const SearchGptKeywordsStoreKeywords = (
  payload: types.SearchGptKeywordsStoreKeywordsAction["payload"]
): types.SearchGptKeywordsActionTypes => ({
  type: types.SearchGptKeywordsStoreKeywords,
  payload,
})

export const SearchGptKeywordsSetIsFetching = (
  payload: types.SearchGptKeywordsSetIsFetchingAction["payload"]
): types.SearchGptKeywordsActionTypes => ({
  type: types.SearchGptKeywordsSetIsFetching,
  payload,
})

export const SearchGptKeywordsSetSelectedKeywords = (
  payload: types.SearchGptKeywordsSetSelectedKeywordsAction["payload"]
): types.SearchGptKeywordsActionTypes => ({
  type: types.SearchGptKeywordsSetSelectedKeywords,
  payload,
})

export const SearchGptKeywordsSetSelectedKeywordsToCreate = (
  payload: types.SearchGptKeywordsSetSelectedKeywordsToCreateAction["payload"]
): types.SearchGptKeywordsActionTypes => ({
  type: types.SearchGptKeywordsSetSelectedKeywordsToCreate,
  payload,
})

export const SearchGptKeywordsSelectedKeywordsToCreateRemoveKeyword = (
  payload: types.SearchGptKeywordsSelectedKeywordsToCreateRemoveKeywordAction["payload"]
): types.SearchGptKeywordsActionTypes => ({
  type: types.SearchGptKeywordsSelectedKeywordsToCreateRemoveKeyword,
  payload,
})

export const SearchGptKeywordsSetAddKeywordsModalIsOpen = (
  payload: types.SearchGptKeywordsSetAddKeywordsModalIsOpenAction["payload"]
): types.SearchGptKeywordsActionTypes => ({
  type: types.SearchGptKeywordsSetAddKeywordsModalIsOpen,
  payload,
})

export const SearchGptKeywordsSetAddKeywordsModalInput = (
  payload: types.SearchGptKeywordsSetAddKeywordsModalInputAction["payload"]
): types.SearchGptKeywordsActionTypes => ({
  type: types.SearchGptKeywordsSetAddKeywordsModalInput,
  payload,
})

export const SearchGptKeywordsStoreInputToKeywords =
  (): types.SearchGptKeywordsActionTypes => ({
    type: types.SearchGptKeywordsStoreInputToKeywords,
  })

export const SearchGptKeywordsRemoveAllKeywordsForCreate =
  (): types.SearchGptKeywordsActionTypes => ({
    type: types.SearchGptKeywordsRemoveAllKeywordsForCreate,
  })

export const SearchGptKeywordsRemoveAllKeywordsForDelete =
  (): types.SearchGptKeywordsActionTypes => ({
    type: types.SearchGptKeywordsRemoveAllKeywordsForDelete,
  })

export const SearchGptKeywordsSetFiltersCountry = (
  payload: types.SearchGptKeywordsSetFiltersCountryAction["payload"]
): types.SearchGptKeywordsActionTypes => ({
  type: types.SearchGptKeywordsSetFiltersCountry,
  payload,
})

export const SearchGptKeywordsSetFiltersDevice = (
  payload: types.SearchGptKeywordsSetFiltersDeviceAction["payload"]
): types.SearchGptKeywordsActionTypes => ({
  type: types.SearchGptKeywordsSetFiltersDevice,
  payload,
})

export const SearchGptKeywordsStoreKeywordsImage = (
  payload: types.SearchGptKeywordsStoreKeywordsImageAction["payload"]
): types.SearchGptKeywordsActionTypes => ({
  type: types.SearchGptKeywordsStoreKeywordsImage,
  payload,
})

export const $SearchGptKeywordsSetFiltersDevice =
  (
    payload: types.SearchGptKeywordsSetFiltersDeviceAction["payload"]
  ): ThunkAction<any, RootState, any, any> =>
  async (dispatch, getState) => {
    dispatch(SearchGptKeywordsSetFiltersDevice(payload))
    await dispatch($fetchSearchGptKeywords())
  }

export const SearchGptKeywordsSetFiltersSource = (
  payload: types.SearchGptKeywordsSetFiltersSourceAction["payload"]
): types.SearchGptKeywordsActionTypes => ({
  type: types.SearchGptKeywordsSetFiltersSource,
  payload,
})

export const $SearchGptKeywordsSetFiltersSource =
  (
    payload: types.SearchGptKeywordsSetFiltersSourceAction["payload"]
  ): ThunkAction<any, RootState, any, any> =>
  async (dispatch, getState) => {
    dispatch(SearchGptKeywordsSetFiltersSource(payload))
    await dispatch($fetchSearchGptKeywords())
  }

export const $SearchGptKeywordsSetFiltersCountry =
  (
    payload: types.SearchGptKeywordsSetFiltersCountryAction["payload"]
  ): ThunkAction<any, RootState, any, any> =>
  async (dispatch, getState) => {
    dispatch(SearchGptKeywordsSetFiltersCountry(payload))
    await dispatch($fetchSearchGptKeywords())
  }

export const SearchGptKeywordsReset =
  (): types.SearchGptKeywordsActionTypes => ({
    type: types.SearchGptKeywordsReset,
  })

const showPaywall = (props: {
  signupDate: Date
  plans: Set<PaymentPlansEntity>
}) => {
  return false
}

export const $fetchSearchGptKeywords =
  (): ThunkAction<any, RootState, any, any> => async (dispatch, getState) => {
    const { di, websites, searchGptKeywords, payments, auth } = getState()
    const { feature } = getWebsiteIdFromUrl(di.LocationService.getFullUrl())

    if (feature !== "search-gpt") {
      return
    }

    if (
      showPaywall({
        signupDate: auth.user?.created_at || new Date(),
        plans: payments.plans,
      })
    ) {
      return dispatch(
        actions.payments.$PaymentsOpenModal({
          value: true,
          type: PaymentPlansEntity.indexation,
          source: "indexation/trial-expired",
          isClosable: false,
        })
      )
    }

    const websiteId = websites.activeWebsite

    if (!websiteId) {
      return alert("No websiteId provided")
    }

    dispatch(SearchGptKeywordsSetIsFetching({ value: true }))

    const res = await di.KeywordsRepository.fetch({
      website: websiteId,
      filter: {
        country: searchGptKeywords.filters.country,
        device: searchGptKeywords.filters.device,
        source: searchGptKeywords.filters.source,
      },
    })

    dispatch(SearchGptKeywordsSetIsFetching({ value: false }))

    if (res.error) {
      return dispatch(
        actions.notifications.create({
          type: "error",
          message: res.code,
        })
      )
    }

    dispatch(SearchGptKeywordsStoreKeywords({ keywords: res.body }))
  }

export const $createSearchGptKeywords =
  (): ThunkAction<any, RootState, any, any> => async (dispatch, getState) => {
    const { di, searchGptKeywords, websites } = getState()
    const websiteId = websites.activeWebsite

    if (!websiteId) {
      throw new Error("No websiteId provided")
    }

    if (searchGptKeywords.selectedKeywordsToCreate.size === 0) {
      return dispatch(
        actions.notifications.create({
          type: "error",
          message: NotificationMessageEntity.WEBSITES_SITEMAP_UPDATE_EMPTY,
        })
      )
    }

    dispatch(SearchGptKeywordsSetIsFetching({ value: true }))

    const res = await di.KeywordsRepository.create({
      websiteId: websiteId,
      keywords: Array.from(searchGptKeywords.selectedKeywordsToCreate.values()),
    })

    dispatch(SearchGptKeywordsSetIsFetching({ value: false }))

    if (res.error) {
      return dispatch(
        actions.notifications.create({
          type: "error",
          message: res.code,
        })
      )
    }

    dispatch(SearchGptKeywordsRemoveAllKeywordsForCreate())
    dispatch(SearchGptKeywordsSetAddKeywordsModalIsOpen({ value: false }))

    dispatch(
      actions.notifications.create({
        type: "success",
        message: NotificationMessageEntity.KEYWORDS_ADD_SUCCESS,
      })
    )

    dispatch($fetchSearchGptKeywords())
  }

export const $deleteSearchGptKeywords =
  (): ThunkAction<any, RootState, any, any> => async (dispatch, getState) => {
    const { di, searchGptKeywords, websites } = getState()

    const websiteId = websites.activeWebsite

    if (!websiteId) {
      throw new Error("No websiteId provided")
    }

    if (searchGptKeywords.selectedKeywordsToDelete.size === 0) {
      return dispatch(
        actions.notifications.create({
          type: "error",
          message: NotificationMessageEntity.WEBSITES_SITEMAP_UPDATE_EMPTY,
        })
      )
    }

    dispatch(SearchGptKeywordsSetIsFetching({ value: true }))

    const res = await di.KeywordsRepository.delete({
      websiteId: websiteId,
      keywords: Array.from(searchGptKeywords.selectedKeywordsToDelete.values()),
    })

    dispatch(SearchGptKeywordsRemoveAllKeywordsForDelete())

    dispatch(SearchGptKeywordsSetIsFetching({ value: false }))

    if (res.error) {
      return dispatch(
        actions.notifications.create({
          type: "error",
          message: res.code,
        })
      )
    }

    dispatch($fetchSearchGptKeywords())
  }
export const $check_if_user_can_access_search_gpt =
  (): ThunkAction<any, RootState, any, any> => async (dispatch, getState) => {
    const { auth, payments, di } = getState()

    if (payments.search_gpt_can_access) {
      return
    }

    const res = await di.PaymentsRepository.fetch_search_gpt_access()

    console.log(res)

    if (res.error) {
      return alert(res.code)
    }

    dispatch(actions.payments.PaymentsStoreSearchGptAccess(res.body.can_access))
  }
