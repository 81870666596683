import { KeywordWithPositionEntity } from "../../entities/KeywordEntity"

export const SearchGptKeywordsStoreKeywords = "SearchGptKeywordsStoreKeywords"
export interface SearchGptKeywordsStoreKeywordsAction {
  type: typeof SearchGptKeywordsStoreKeywords
  payload: {
    keywords: KeywordWithPositionEntity[]
  }
}

export const SearchGptKeywordsStoreKeywordsImage =
  "SearchGptKeywordsStoreKeywordsImage"
export interface SearchGptKeywordsStoreKeywordsImageAction {
  type: typeof SearchGptKeywordsStoreKeywordsImage
  payload: {
    image: string | null
  }
}

export const SearchGptKeywordsSetIsFetching = "SearchGptKeywordsSetIsFetching"
export interface SearchGptKeywordsSetIsFetchingAction {
  type: typeof SearchGptKeywordsSetIsFetching
  payload: {
    value: boolean
  }
}

export const SearchGptKeywordsSetSelectedKeywords =
  "SearchGptKeywordsSetSelectedKeywords"
export interface SearchGptKeywordsSetSelectedKeywordsAction {
  type: typeof SearchGptKeywordsSetSelectedKeywords
  payload: {
    keyword: string
  }
}

export const SearchGptKeywordsSetSelectedKeywordsToCreate =
  "SearchGptKeywordsSetSelectedKeywordsToCreate"
export interface SearchGptKeywordsSetSelectedKeywordsToCreateAction {
  type: typeof SearchGptKeywordsSetSelectedKeywordsToCreate
  payload: {
    keyword: string
  }
}

export const SearchGptKeywordsSetAddKeywordsModalIsOpen =
  "SearchGptKeywordsSetAddKeywordsModalIsOpen"
export interface SearchGptKeywordsSetAddKeywordsModalIsOpenAction {
  type: typeof SearchGptKeywordsSetAddKeywordsModalIsOpen
  payload: {
    value: boolean
  }
}

export const SearchGptKeywordsSetAddKeywordsModalInput =
  "SearchGptKeywordsSetAddKeywordsModalInput"
export interface SearchGptKeywordsSetAddKeywordsModalInputAction {
  type: typeof SearchGptKeywordsSetAddKeywordsModalInput
  payload: {
    value: string
  }
}

export const SearchGptKeywordsStoreInputToKeywords =
  "SearchGptKeywordsStoreInputToKeywords"
export interface SearchGptKeywordsStoreInputToKeywordsAction {
  type: typeof SearchGptKeywordsStoreInputToKeywords
}

export const SearchGptKeywordsRemoveAllKeywordsForCreate =
  "SearchGptKeywordsRemoveAllKeywordsForCreate"
export interface SearchGptKeywordsRemoveAllKeywordsForCreateAction {
  type: typeof SearchGptKeywordsRemoveAllKeywordsForCreate
}

export const SearchGptKeywordsRemoveAllKeywordsForDelete =
  "SearchGptKeywordsRemoveAllKeywordsForDelete"
export interface SearchGptKeywordsRemoveAllKeywordsForDeleteAction {
  type: typeof SearchGptKeywordsRemoveAllKeywordsForDelete
}

export const SearchGptKeywordsReset = "SearchGptKeywordsReset"
export interface SearchGptKeywordsResetAction {
  type: typeof SearchGptKeywordsReset
}

export const SearchGptKeywordsSetFiltersCountry =
  "SearchGptKeywordsSetFiltersCountry"
export interface SearchGptKeywordsSetFiltersCountryAction {
  type: typeof SearchGptKeywordsSetFiltersCountry
  payload: {
    country: string
  }
}

export const SearchGptKeywordsSetFiltersDevice =
  "SearchGptKeywordsSetFiltersDevice"
export interface SearchGptKeywordsSetFiltersDeviceAction {
  type: typeof SearchGptKeywordsSetFiltersDevice
  payload: {
    device: "desktop" | "mobile" | "tablet" | null
  }
}

export const SearchGptKeywordsSetFiltersSource =
  "SearchGptKeywordsSetFiltersSource"
export interface SearchGptKeywordsSetFiltersSourceAction {
  type: typeof SearchGptKeywordsSetFiltersSource
  payload: {
    source: "google" | "bing" | "yandex" | null
  }
}

export const SearchGptKeywordsSelectedKeywordsToCreateRemoveKeyword =
  "SearchGptKeywordsSelectedKeywordsToCreateRemoveKeyword"
export interface SearchGptKeywordsSelectedKeywordsToCreateRemoveKeywordAction {
  type: typeof SearchGptKeywordsSelectedKeywordsToCreateRemoveKeyword
  payload: {
    keyword: string
  }
}

export type SearchGptKeywordsActionTypes =
  | SearchGptKeywordsStoreKeywordsAction
  | SearchGptKeywordsSetIsFetchingAction
  | SearchGptKeywordsSetSelectedKeywordsAction
  | SearchGptKeywordsSetSelectedKeywordsToCreateAction
  | SearchGptKeywordsSetAddKeywordsModalIsOpenAction
  | SearchGptKeywordsSetAddKeywordsModalInputAction
  | SearchGptKeywordsStoreInputToKeywordsAction
  | SearchGptKeywordsRemoveAllKeywordsForCreateAction
  | SearchGptKeywordsRemoveAllKeywordsForDeleteAction
  | SearchGptKeywordsResetAction
  | SearchGptKeywordsSetFiltersCountryAction
  | SearchGptKeywordsSetFiltersDeviceAction
  | SearchGptKeywordsSetFiltersSourceAction
  | SearchGptKeywordsSelectedKeywordsToCreateRemoveKeywordAction
  | SearchGptKeywordsStoreKeywordsImageAction
